<template>
  <div class="box">

    <div class="bj">
      <div class="w128">
        <div class="Breadcrumb">
          <span @click="goindex" style="cursor: pointer;">全部课程</span> > <span@click="goindex" style="cursor: pointer;">行业分类</span@click=> >
          <span @click="goback" style="cursor: pointer;">{{
            $route.query.type
          }}</span>
          >
          {{ details.title }}
        </div>
        <div class="content">
          <div class="left">
            <img :src="details.pic" alt="" />
          </div>
          <div class="rigth">
            <div class="title">
              {{ details.title }}
            </div>
            <div class="class">
               ￥{{details.cost}}<span>元</span>
               <!-- <span v-if="details.payment==0||details.payment==null">免费</span> 
                <span v-if="details.payment-0>=1">
                    <span v-if="className=='岗位培训'">
                        {{details.cost}} 元/人/课时
                    </span>
                    <span v-else>
                        ￥{{details.cost}}<span>元</span>
                    </span>
                </span> -->
              <span class="span" v-if="details.boutique == 1">自主课</span>
              <span class="span" v-if="details.boutique == 0">官方课</span>
            </div>

            <div class="list">
              <div @click="loves">
                <img v-if="!love"  src="../../assets/shoucang_icon.png" alt="" />
                <img v-else  src="../../assets/yishoucang_icon.png" alt="" />收藏此课
              </div>
              <div @click="fx">
                <img src="../../assets/fenxiang-icon.png" alt="" />分享此课
              </div>
              <div @click="jskt">
                <img src="../../assets/shoujiketang_icon.png" alt="" />手机课堂
              </div>
            </div>
            <div class="people">累计报名 {{ details.stu }}人</div>
            <div
              class="btn"
              v-if="
                details.payment == 0 && details.cost == 0 && details.order == 0
              "
              @click="studyBack1(1)"
            >
              去学习
            </div>
           
            <template v-if="details.order == 0">
              <!-- <div
                class="cancel btn"
                v-if="details.payment >= 1 || details.cost > 0"
                @click="studyBack(2)"
              >
                免费试学
              </div> -->
              <div
                class="confirm btn"
                v-if="details.payment >= 1 || details.cost > 0"
                @click="zf"
              >
                购买课程
              </div>
            </template>
             <div class="btn" v-else  @click="studyBack(1)">
             已购买去学习
            </div>
        
          </div>
        </div>
        <div class="content1">
          <div class="left">
            <div class="tablist">
              <div
                @click="active = 1"
                :class="active == 1 ? 'item active' : 'item'"
                style="margin-left: 50px"
              >
                课程概述
              </div>
              <div
                @click="active = 2"
                :class="active == 2 ? 'item active' : 'item'"
              >
                课程目录
              </div>
              <div
                @click="active = 3"
                :class="active == 3 ? 'item active' : 'item'"
              >
                课程资源
              </div>
            </div>
            <div v-if="active == 1" class="module1">
              <div
                v-if="details.kcjs == ''"
                style="text-align: center;font-size: 14px;"
              >
                暂无课程描述
              </div>
              <div v-else v-html="details.kcjs"></div>
            </div>
            <div v-if="active == 2" class="module2">
              <div v-if="chapter.length > 0">
                <div
                  class="item"
                  v-for="(item, index) in chapter"
                  :key="index"
                >
                  <div>{{ item.title }}</div>
                </div>
              </div>
              <div v-else style="text-align: center;font-size: 14px;">
                暂无课程目录
              </div>
            </div>
            <div v-if="active == 3" class="module3">
              <div v-if="details.qtxx">{{ item.qtxx }}</div>
              <div v-else style="text-align: center; font-size: 14px;">
                暂无资源
              </div>
              <!-- <div class="list">
                <div class="item">
                  <div
                    style="
                      font-size: 14px;
                      font-family: PingFangSC-Regular, PingFang SC;
                      font-weight: 400;
                      color: #a7a7a7;
                    "
                  >
                    <img src="../../assets/xuexi_click_icon.png" alt="" />学员
                    240aykt2355041151147
                  </div>
                  <div class="item_11">
                    <div
                      style="
                        margin-left: 54px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #222222;
                      "
                    >
                      学员提问：老师讲的真不错，请问怎样整理知识点教学？
                    </div>
                    <div
                      style="
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #a7a7a7;
                      "
                    >
                      提问时间：2021-08-24 21:39
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div
                    style="
                      font-size: 14px;
                      font-family: PingFangSC-Regular, PingFang SC;
                      font-weight: 400;
                      color: #a7a7a7;
                    "
                  >
                    <img src="../../assets/xuexi_click_icon.png" alt="" />学员
                    240aykt2355041151147
                  </div>
                  <div class="item_11">
                    <div
                      style="
                        margin-left: 54px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #222222;
                      "
                    >
                      学员提问：老师讲的真不错，请问怎样整理知识点教学？
                    </div>
                    <div
                      style="
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #a7a7a7;
                      "
                    >
                      提问时间：2021-08-24 21:39
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div
                    style="
                      font-size: 14px;
                      font-family: PingFangSC-Regular, PingFang SC;
                      font-weight: 400;
                      color: #a7a7a7;
                    "
                  >
                    <img src="../../assets/xuexi_click_icon.png" alt="" />学员
                    240aykt2355041151147
                  </div>
                  <div class="item_11">
                    <div
                      style="
                        margin-left: 54px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #222222;
                      "
                    >
                      学员提问：老师讲的真不错，请问怎样整理知识点教学？
                    </div>
                    <div
                      style="
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #a7a7a7;
                      "
                    >
                      提问时间：2021-08-24 21:39
                    </div>
                  </div>
                </div>
                <div class="item">
                  <div
                    style="
                      font-size: 14px;
                      font-family: PingFangSC-Regular, PingFang SC;
                      font-weight: 400;
                      color: #a7a7a7;
                    "
                  >
                    <img src="../../assets/xuexi_click_icon.png" alt="" />学员
                    240aykt2355041151147
                  </div>
                  <div class="item_11">
                    <div
                      style="
                        margin-left: 54px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #222222;
                      "
                    >
                      学员提问：老师讲的真不错，请问怎样整理知识点教学？
                    </div>
                    <div
                      style="
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #a7a7a7;
                      "
                    >
                      提问时间：2021-08-24 21:39
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
          <div class="rigth">
            <!-- <div class="title">课程介绍</div> -->
            <div class="title">老师介绍</div>
            <div class="content11">
              <!-- <div class="box1">
                <img src="../../assets/school_moren_icon'.png" alt="" />{{
                  details.school
                }}
              </div> -->
              <!-- <div class="box2">
                <div class="box_1">
                  <div>
                    <img src="../../assets/kechengshu_icon.png" alt="" />课程数
                  </div>
                  <div style="text-align: center; margin-top: 16px">
                    {{ details.ks  || 0}}
                  </div>
                </div>
                <div class="box_1">
                  <div>
                    <img
                      src="../../assets/xuexirenci_icon.png"
                      alt=""
                    />学习人次
                  </div>
                  <div style="text-align: center; margin-top: 16px">
                    {{ details.stu || 0}}
                  </div>
                </div>
                <div class="box_1">
                  <div>
                    <img src="../../assets/manyidu_icon.png" alt="" />满意度
                  </div>
                  <div style="text-align: center; margin-top: 16px">暂无信息</div>
                </div>
              </div> -->
              <!-- <div class="box3">
                从业跨境电商十余年，熟悉亚马逊前台后台，掌握千万大卖用的Lsting的精细编写方法。打造暴利店铺的选品思路，丰富运营经验，精通平台各个环节。让所有电商中小卖家，经营店铺不再困难，让天下没有难学的电商运营技巧，经营理念是互助，开放，共享。从0基础开始教学，坚持有问必答，有问必解决的学习分享精神。
              </div> -->
              <!-- <div class="title">老师介绍</div> -->
              <div class="box3">
                {{ details.synopsis || '暂无介绍'}}
              </div>
              <div class="title">老师信息</div>
              <div class="box4">
                <div class="box4_4">
                  <img src="../../assets/morentouxiang_icon.png" alt="" />
                  <div>
                    <div
                      style="
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #343434;
                      "
                    >
                      老师名称：
                    </div>
                    <div
                      style="
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #137cfb;
                      "
                    >
                      {{ details.username || '暂无信息'}}
                    </div>
                  </div>
                </div>
                <div class="box4_41">
                  <div>
                    <div>
                      <img src="../../assets/shouji_icon.png" alt="" />老师手机
                    </div>
                    <div
                      style="
                        margin-top: 16px;
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #137cfb;
                      "
                    >
                      暂无信息
                    </div>
                  </div>
                  <div>
                    <div>
                      <img src="../../assets/weixin_icon.png" alt="" />老师微信
                    </div>
                    <div
                      style="
                        margin-top: 16px;

                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #137cfb;
                      "
                    >
                      暂无信息
                    </div>
                  </div>
                </div>
                <!-- <div class="btn">课程答疑</div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
     <div class="mask1" v-show="flags">
      <div>
        <img class="imgs" src="../../assets/icon.png" alt="" />
        <div class="popup">
          <div class="title1">学习身份核验</div>
          <div class="content" v-show="num == 1">
            在开始您的学习之前我们将对您进行学习身份核验届时会有核验短信发到您的手机请确认通过
          </div>
          <el-checkbox
            v-show="num == 1"
            style="margin-left: 45px"
            v-model="checked"
            >同意《职培通在线教育云平台学员核验规定》</el-checkbox
          >
          <div class="content111" v-show="num == 2">
            <input
              class="iut"
              v-model="userInfo.phone"
              disabled
              type="text"
              placeholder="手机号"
            />
            <div class="dis">
              <input
                v-model="verify"
                class="iut1"
                type="text"
                placeholder="验证码"
              />
              <div class="btn" v-if="time < 0" @click="getCode">获取验证码</div>
              <div class="btn" v-if="time >= 0">{{ time }} s</div>
            </div>
          </div>
          <div class="content2" v-show="num == 3">
            <img src="../../assets/wc.png" alt="" />
            <div>核验成功</div>
          </div>
          <div class="dian" @click="submit">
            {{
              num == 1
                ? "开始核验"
                : num == 2
                ? "提交核验"
                : num == 3
                ? "去学习"
                : ""
            }}
          </div>
        </div>
        <img class="img" @click="close" src="../../assets/close.png" alt="" />
      </div>
    </div>
    <div class="divform"></div>
    <div id="alipay_submit"></div>
    <!-- //支付 -->
    <el-dialog
    top="45vh"
      title="支付"
      :visible.sync="dialogVisible"
      width="30%"
      center
      >
      <div class="zflist">
          <div class="item" @click="gg(1)">
            <img src="../../assets/weixin_zhifu_icon.png" alt=""> 微信支付</div>
          <div class="item" @click="gg(2)">
             <img src="../../assets/zhifubao_icon.png" alt=""> 支付宝支付</div>
        </div>
      <div  class="code">
        <div  class="qrcode" id="qrcode" ref="qrCodeUrl"></div>
      </div>
      <div style="height: 170px;"></div>
      <div style="text-align: center;">若支付成功请刷新页面</div>
      
    </el-dialog>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      dialogVisible: false,
      active: 1,
      details: {},
      chapter: [],
      love: false,
      flag: 1,
      qrcode: null,
      code_url: "",
      sss: "1212121",
      time: -1,
      verify: "",
      flags: false,
      num: 1,
      checked: false,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
      learning: (state) => state.learning,
    }),
  },
  methods: {
    ...mapActions([
      "getClassDet",
      "getCatalog",
      "getCancelCollection",
      "getAddCollection",
      "getclassDetail",
      "getFreePurchase",
      "getpcGenerateOrder",
      "getVerify",
      "getsmsVerificationr",
    ]),
    //倒计时
    getTime() {
      var tiemr = setInterval(() => {
        if (this.time >= 0) {
          this.time--;
        } else {
          clearInterval(tiemr);
        }
      }, 1000);
    },
    //获取验证码
    getCode() {
      this.getVerify({
        kindof: 1,
        phone: this.userInfo.phone,
      }).then((res) => {
        if (res.code == 200) {
          this.time = 100;
          this.getTime();
        }
      });
    },
    gostudy(item) {
      this.$store.commit("SET_CLASSID", item.id);

      if (this.learning == 1) {
        this.flags = true;
      } else {
        this.$router.push("/Layoutstudy/studyLecture");
      }
    },
    close() {
      this.flags = false;
      this.num = 1;
    },
    submit() {
      if (this.num == 1) {
        if (this.checked == false) {
          this.$message({
            message: "请勾选协议",
            type: "warning",
            offset: 80,
          });
        } else {
          ++this.num;
          return;
        }
      }
      if (this.num == 2) {
        if (this.verify == "") {
          this.$message({
            message: "请填写验证码",
            type: "error",
            offset: 80,
          });
          return;
        }
        this.getsmsVerificationr({
          id: this.userInfo.id,
          verify: this.verify,
          phone: this.userInfo.phone,
          username: this.userInfo.username,
        }).then((res) => {
          if (res.code == 200) {
            ++this.num;
            this.$store.commit("SET_LEARNING", 2);
          } else {
            this.$message({
              message: res.msg,
              type: "error",
              offset: 80,
            });
          }
        });
        return;
      }
      if (this.num == 3) {
        this.$router.push("/Layoutstudy/studyLecture");
        return;
      }
    },
    //分享
    fx() {
      let url = window.location.href;
      var temp = document.createElement("textarea");
      temp.value = url;
      document.body.appendChild(temp);
      temp.select(); // 选择对象
      document.execCommand("Copy");
      this.$message({
        message: "链接已复制",
        type: "success",
      });
    },
    // 手机链接
    jskt() {
      var temp = document.createElement("textarea");
      temp.value = `https://mp.zpton.com/drillDetails?id${this.details.id}&title=${this.details.title}&pic=${this.details.pic}&short=${this.details.short}&cost=${this.details.cost}&ks=${this.details.ks}&professional=${this.details.professional}&payment=${this.details.payment}&position=${this.details.position}&stu=${this.details.stu}&type=${this.details.type}`;
      document.body.appendChild(temp);
      temp.select(); // 选择对象
      document.execCommand("Copy");
      this.$message({
        message: "链接已复制前往手机打开",
        type: "success",
      });
    },
    zf() {
      this.dialogVisible = true;
    },
    gg(order_type) {
      let that = this;
      if (this.flag != 1) {
      }
      this.getpcGenerateOrder({
        username: this.userInfo.username,
        classid: this.details.class ? this.details.class : this.details.id,
        id: this.details.class ? this.details.id : "",
        wx_code: "",
        order_type: order_type,
        type: this.payType ? this.payType : 1,
        cost: this.details.cost,
      }).then((res) => {
        if (res.code == 200) {
          that.dialogVisible = true;
          if (order_type == 1) {
            if (that.flag == 1) {
              that.qrcode = new QRCode(that.$refs.qrCodeUrl, {
                text: res.data.code_url,
                width: 150,
                height: 150,
                colorDark: "#000000",
                colorLight: "#ffffff",
                correctLevel: QRCode.CorrectLevel.H,
              });
            }
            that.flag = 2;
          }
        }
        if (order_type == 2) {
          let routerData = this.$router.resolve({
            path: "/applyText",
            query: {
              htmls: res,
            },
          });
          window.open(routerData.href, "_blank");
          const div = document.createElement("div");
          div.innerHTML = htmls;
          document.body.appendChild(div);
          document.forms[0].submit();
        }
      });
    },
    //去学习
    studyBack(val) {
      this.$store.commit("SET_CLASSID", this.$route.query.id);
      this.$store.commit("SET_STUDYTYPE", val);
      if (this.learning == 1) {
        this.flags = true;
      } else {
        this.$router.push("/Layoutstudy/studyLecture");
      }
    },
    //去学习
    studyBack1(val) {
      this.$store.commit("SET_CLASSID", this.$route.query.id);
      this.getFreePurchase({
        code: "",
        class: this.$route.query.id,
        username: this.userInfo.username,
      }).then((res) => {
        if (res.code == 200) {
          this.$store.commit("SET_STUDYTYPE", val);
          if (this.learning == 1) {
            this.flags = true;
          } else {
            this.$router.push("/Layoutstudy/studyLecture");
          }
        }
      });
    },
    loves() {
      if (this.love) {
        this.getCancelCollection({
          username: this.userInfo.username,
          id: this.details.id,
        }).then((res) => {
          this.$message({
            message: res.msg,
            type: res.code == 200 ? "success" : "cancel",
          });
          if (res.code == 200) {
            this.love = false;
          }
        });
      } else {
        this.getAddCollection({
          username: this.userInfo.username,
          id: this.details.id,
        }).then((res) => {
          this.$message({
            message: res.msg,
            type: res.code == 200 ? "success" : "cancel",
          });
          if (res.code == 200 || res.code == 0) {
            this.love = true;
          }
        });
      }
    },
    goback() {
      this.$router.go(-1);
    },
    goindex() {
      this.$router.push("/training");
    },
  },
  mounted() {
    // this.getclassDetail({
    //   id: this.$route.query.id, //班级id
    // });
    this.getClassDet({
      username: this.userInfo.username,
      classid: this.$route.query.id,
    }).then((res) => {
      if (res.code == 200) {
        this.details = res.data.list;
      }
    });
    this.getCatalog({
      username: this.userInfo.username,
      classid: this.$route.query.id,
      userid: this.userInfo.id,
    }).then((res) => {
      if (res.code == 200) {
        this.chapter = res.data.chapter;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.mask1 {
  position: fixed;
  z-index: 99;

  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  .imgs {
    display: block;
    width: 250px;
    margin: 0 auto;
    vertical-align: middle;
  }
  .img {
    width: 40px;
    height: 40px;
    display: block;
    margin: 15px auto;
    cursor: pointer;
  }
  .popup {
    position: relative;
    z-index: 100;
    width: 414px;
    height: 377px;
    background: #ffffff;
    border-radius: 16px;
    .title1 {
      padding-top: 29px;
      text-align: center;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #008bd5;
      line-height: 33px;
      span {
        font-size: 18px;
      }
      .span {
        font-weight: 500;
        font-size: 16px;
        color: #000;
      }
    }
    .content {
      margin: 20px 40px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
    }
    .content111 {
      margin: 20px 29px;
      .iut {
        outline: none;
        height: 40px;
        line-height: 30px;
        width: 360px;
        font-size: 18px;
        border: 0px none;
        border-bottom: 1px solid #cfcfcf;
      }
      .dis {
        margin-top: 23px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #cfcfcf;
        .btn {
          cursor: pointer;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #222222;
        }
      }
      .iut1 {
        font-size: 18px;
        outline: none;
        height: 40px;
        line-height: 30px;
        border: 0px none;
      }
    }
    .content2 {
      img {
        display: block;
        width: 60px;
        height: 60px;
        margin: 29px auto 18px;
      }
      div {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #222222;
        text-align: center;
      }
    }
    .dian {
      border-top: 2px solid #eaeaea;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 50px;
      width: 100%;
      line-height: 50px;
      margin-top: 15px;
      text-align: center;

      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #0067ff;
      cursor: pointer;
    }
  }
}
.bj {
  overflow: hidden;

  background: url("../../assets/beijingtu.png");
}
.w128 {
  width: 1280px;
  margin: 0 auto;
}
.Breadcrumb {
  margin: 31px 0 14px 0;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #6c6c6c;
}
.zflist {
  display: flex;
  justify-content: space-around;
  .item {
    img {
      width: 40px;
      height: 40px;
      vertical-align: middle;
    }
    cursor: pointer;
    width: 170px;
    height: 50px;
    font-size: 16px;
    text-align: center;
    line-height: 50px;
    border: 1px solid #e1e1e1;
  }
}
.code {
  position: relative;
  .qrcode {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}
.content {
  display: flex;
  .left {
    margin-right: 19px;
    width: 720px;
    height: 430px;
    img {
      width: 720px;
      height: 430px;
    }
  }
  .rigth {
    width: 541px;
    position: relative;
    .title {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
    }
    .class1 {
      margin-top: 13px;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;

      color: #137cfb;
    }
    .class {
      margin-top: 13px;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;

      color: #ff4300;
      .span {
        color: #aeaeae;
      }
    }
    .list {
      margin-top: 39px;
      display: flex;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #6c6c6c;
      div {
        cursor: pointer;
        margin-right: 64px;
      }
      img {
        vertical-align: middle;
        margin-right: 3px;
      }
    }
    .people {
      margin-top: 30px;
      padding-left: 18px;
      box-sizing: border-box;
      height: 50px;
      background: #f6f6f6;
      line-height: 50px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #6c6c6c;
    }
    .btn {
      cursor: pointer;
      position: absolute;
      bottom: 0;
      height: 60px;
      line-height: 60px;
      text-align: center;
      color: #fff;
      width: 100%;
      background: #137cfb;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
.content1 {
  display: flex;
  justify-content: space-between;
  .left {
    width: 885px;
    .tablist {
      display: flex;
      line-height: 66px;
      width: 885px;
      height: 66px;
      background: #ffffff;
      border-bottom: 1px solid #e1e1e1;
      .item {
        cursor: pointer;
        position: relative;
        margin-right: 118px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #222222;
      }
      .active {
        &::before {
          position: absolute;
          content: "";
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 96px;
          height: 4px;
          background: #137cfb;
        }
      }
    }
  }
  .rigth {
    width: 376px;
    .title {
      height: 66px;
      line-height: 66px;
      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #222222;
      background: #ffffff;
      border-bottom: 1px solid #e1e1e1;
    }
    .content11 {
      // padding: 0 27px;
      box-sizing: border-box;
      .box1 {
        display: block;
        margin-top: 13px;
        font-size: 18px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #222222;
        img {
          margin-right: 8px;
          vertical-align: middle;
        }
      }
      .box2 {
        margin-top: 48px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #6c6c6c;
        display: flex;
        justify-content: space-around;
        img {
          vertical-align: middle;
          margin-right: 10px;
        }
      }
      .box3 {
        margin-top: 10px;
        margin-bottom: 150px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #919191;
      }
      .box4 {
        margin-top: 23px;
        .box4_4 {
          display: flex;
          img {
            margin-right: 10px;
            width: 50px;
            height: 50px;
          }
        }
        .box4_41 {
          margin-bottom: 150px;
          margin-top: 33px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #6c6c6c;
          display: flex;
          justify-content: space-around;
          img {
            margin-right: 8px;
            vertical-align: middle;
          }
        }
        .btn {
          margin-top: 27px;
          margin-bottom: 82px;
          text-align: center;
          line-height: 39px;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #6c6c6c;
          height: 39px;
          background: #d8d8d8;
        }
      }
    }
  }
}
::v-deep .module1 {
  padding: 39px;
  box-sizing: border-box;
  width: 100%;
  img {
    display: block;
    width: 100%;
  }
}
.module2 {
  padding: 50px;
  box-sizing: border-box;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #343434;
  .item {
    width: 594px;
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
}
.module3 {
  padding: 50px;
  box-sizing: border-box;
  .item {
    margin-bottom: 45px;
    img {
      margin-right: 19px;
      width: 35px;
      height: 35px;
      vertical-align: middle;
    }
    .item_11 {
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>
